import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsWithoutDriverTableColumn from "../common/types/order-optimizer-details-without-driver-table-column";

const getColumns = (): OrderOptimizerDetailsWithoutDriverTableColumn[] => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table
      .withoutDriver.headers;

  const tableColumns: OrderOptimizerDetailsWithoutDriverTableColumn[] = [
    {
      header: translations.withoutDriverLabel,
      title: translations.withoutDriverTitle,
      accessor: "withoutDriver",
      colSpan: 3,
    },
    {
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      accessor: "billingModel",
      colSpan: 3,
    },
    {
      header: translations.contractRateLabel,
      title: translations.contractRateTitle,
      accessor: "contractRate",
      colSpan: 3,
    },
    {
      header: translations.totalTimeLabel,
      title: translations.totalTimeTitle,
      accessor: "totalTime",
      colSpan: 3,
    },
    {
      header: translations.haltingTimeLabel,
      title: translations.haltingTimeTitle,
      accessor: "haltingTime",
      colSpan: 3,
    },
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 3,
    },
    {
      header: translations.marginLabel,
      title: translations.marginTitle,
      accessor: "margin",
      colSpan: 3,
    },
  ];

  return tableColumns;
};

const orderOptimizerDetailsWithoutDriverTableHelper = {
  getColumns,
};

export default orderOptimizerDetailsWithoutDriverTableHelper;
