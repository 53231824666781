import OrderOptimizerDetailsRequest from "../../../api/raily/order-optimizer/details/order-optimizer-details.request";
import OrderOptimizerDetailsLoadParams from "./order-optimizer-details-load-params";

const create = (
  params: OrderOptimizerDetailsLoadParams
): OrderOptimizerDetailsRequest => {
  return {
    optimizationId: params.optimizationId,
    taxiDriverAssociationId: params.taxiDriverAssociationId,
  };
};

const orderOptimizerDetailsRequestFactory = {
  create,
};

export default orderOptimizerDetailsRequestFactory;
