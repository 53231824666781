import { round } from "lodash";
import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import { useAppContext } from "../../../../context/app.context";
import orderOptimizerDetailsOrdersTableHelper from "./order-optimizer-details-orders-table.helper";
import { OrderOptimizerDetailsItemOrders } from "../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsOrdersTableRow from "../common/types/order-optimizer-details-orders-table-row";
import uuidService from "../../../../common/utils/uuid/uuid.service";
import orderOptimizerDetailsHelper from "../order-optimizer-details.helper";
import OrderOptimizerDetailsOrdersTableDotComponent from "../dot/order-optimizer-details-orders-table-dot.component";
import orderOptimizerHelper from "../../common/order-optimizer.helper";

type OrderOptimizerDetailsOrdersTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  detailsOrderItems?: OrderOptimizerDetailsItemOrders[];
};

const OrderOptimizerDetailsOrdersTableComponent: FC<
  OrderOptimizerDetailsOrdersTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsOrdersTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createTableRow = (
    detailsOrderItems: OrderOptimizerDetailsItemOrders,
    index: number
  ): OrderOptimizerDetailsOrdersTableRow => {
    const distanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsOrderItems.distance
    );
    const distanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsOrderItems.distance
    );

    const totalTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOrderItems.totalTime
    );
    const totalTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOrderItems.totalTime
    );

    const haltingTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOrderItems.haltingTime
    );
    const haltingTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOrderItems.haltingTime
    );

    const dotColor = orderOptimizerHelper.getOrderColor(index);

    return {
      id: uuidService.generate(),
      value: {
        humanId: (
          <div className="order_optimizer_details_orders_table__humanId">
            <OrderOptimizerDetailsOrdersTableDotComponent color={dotColor} />
            <span title={String(detailsOrderItems.humanId)}>
              {detailsOrderItems.humanId}
            </span>
          </div>
        ),
        cargoCompany: (
          <div title={detailsOrderItems.cargoCompany}>
            {detailsOrderItems.cargoCompany}
          </div>
        ),
        billingModel: (
          <div title={detailsOrderItems.billingModel}>
            {detailsOrderItems.billingModel}
          </div>
        ),
        taxiCorporation: (
          <div title={detailsOrderItems.taxiCorporation}>
            {detailsOrderItems.taxiCorporation}
          </div>
        ),
        totalTime: <div title={totalTimeTitle}>{totalTimeLabel}</div>,
        haltingTime: <div title={haltingTimeTitle}>{haltingTimeLabel}</div>,
        distance: <div title={distanceTitle}>{distanceLabel}</div>,
      },
    };
  };

  const rows: OrderOptimizerDetailsOrdersTableRow[] = useMemo(() => {
    if (!props.detailsOrderItems) return [];

    return props.detailsOrderItems.map((item, index) =>
      createTableRow(item, index)
    );
  }, [props.detailsOrderItems, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderOptimizerDetailsOrdersTableComponent;
