import OrderOptimizerDetails, {
  OrderOptimizerDetailsDriver,
  OrderOptimizerDetailsOptimizedStats,
  OrderOptimizerDetailsOrders,
  OrderOptimizerDetailsOrdersByDriverAba,
  OrderOptimizerDetailsOrdersByDriverSabs,
  OrderOptimizerDetailsSteps,
  OrderOptimizerDetailsStepsEndToHome,
  OrderOptimizerDetailsStepsHomeToStart,
  OrderOptimizerDetailsStepsOrders,
  OrderOptimizerDetailsStepsOrdersPoints,
  OrderOptimizerDetailsUnoptimizedStats,
} from "../../../../common/services/order-optimizer/details/order-optimizer-details";
import OrderOptimizerDetailsItem, {
  OrderOptimizerDetailsItemDriver,
  OrderOptimizerDetailsItemOptimizedStats,
  OrderOptimizerDetailsItemOrders,
  OrderOptimizerDetailsItemOrdersByDriverAba,
  OrderOptimizerDetailsItemOrdersByDriverSabs,
  OrderOptimizerDetailsItemSteps,
  OrderOptimizerDetailsItemStepsEndToHome,
  OrderOptimizerDetailsItemStepsHomeToStart,
  OrderOptimizerDetailsItemStepsOrders,
  OrderOptimizerDetailsItemStepsOrdersPoints,
  OrderOptimizerDetailsItemUnoptimizedStats,
} from "./types/order-optimizer-details-item";

const createDetailsItemStepsOrderPoint = (
  stepsOrderPoint: OrderOptimizerDetailsStepsOrdersPoints
): OrderOptimizerDetailsItemStepsOrdersPoints => ({
  displayName: stepsOrderPoint.displayName,
  distance: stepsOrderPoint.distance,
  driverDistance: stepsOrderPoint.driverDistance,
  driverDrivingTime: stepsOrderPoint.driverDrivingTime,
  driverHaltingTime: stepsOrderPoint.driverHaltingTime,
  driverTime: stepsOrderPoint.driverTime,
  haltingTime: stepsOrderPoint.haltingTime,
  lat: stepsOrderPoint.lat,
  lon: stepsOrderPoint.lon,
  time: stepsOrderPoint.time,
});

const createDetailsItemStepsOrderPoints = (
  stepsOrderPoints: OrderOptimizerDetailsStepsOrdersPoints[]
): OrderOptimizerDetailsItemStepsOrdersPoints[] => {
  return stepsOrderPoints.map(createDetailsItemStepsOrderPoint);
};

const createDetailsItemStepsOrder = (
  stepsOrder: OrderOptimizerDetailsStepsOrders
): OrderOptimizerDetailsItemStepsOrders => ({
  distance: stepsOrder.distance,
  haltingTime: stepsOrder.haltingTime,
  points: createDetailsItemStepsOrderPoints(stepsOrder.points),
  totalTime: stepsOrder.totalTime,
});

const createDetailsItemStepsOrders = (
  stepsOrders: OrderOptimizerDetailsStepsOrders[]
): OrderOptimizerDetailsItemStepsOrders[] => {
  return stepsOrders.map(createDetailsItemStepsOrder);
};

const createDetailsItemStepsHomeToStart = (
  stepsHomeToStart: OrderOptimizerDetailsStepsHomeToStart | null
): OrderOptimizerDetailsItemStepsHomeToStart | null => {
  if (!stepsHomeToStart) return null;
  return {
    displayName: stepsHomeToStart.displayName,
    driverTime: stepsHomeToStart.driverTime,
  };
};

const createDetailsItemStepsEndToHome = (
  stepsEndToHome: OrderOptimizerDetailsStepsEndToHome | null
): OrderOptimizerDetailsItemStepsEndToHome | null => {
  if (!stepsEndToHome) return null;
  return {
    displayName: stepsEndToHome.displayName,
    driverDistance: stepsEndToHome.driverDistance,
    driverDrivingTime: stepsEndToHome.driverDrivingTime,
    driverTime: stepsEndToHome.driverTime,
  };
};

const createDetailsItemSteps = (
  steps: OrderOptimizerDetailsSteps
): OrderOptimizerDetailsItemSteps => {
  return {
    endToHome: createDetailsItemStepsEndToHome(steps.endToHome),
    homeToStart: createDetailsItemStepsHomeToStart(steps.homeToStart),
    orders: createDetailsItemStepsOrders(steps.orders),
  };
};

const createDetailsItemOrdersBySabs = (
  ordersBySabs: OrderOptimizerDetailsOrdersByDriverSabs | null
): OrderOptimizerDetailsItemOrdersByDriverSabs | null => {
  if (!ordersBySabs) return null;
  return {
    distance: ordersBySabs.distance,
    haltingTime: ordersBySabs.haltingTime,
    totalTime: ordersBySabs.totalTime,
  };
};

const createDetailsItemOrdersByAba = (
  ordersByABa: OrderOptimizerDetailsOrdersByDriverAba | null
): OrderOptimizerDetailsItemOrdersByDriverAba | null => {
  if (!ordersByABa) return null;
  return {
    distance: ordersByABa.distance,
    haltingTime: ordersByABa.haltingTime,
    totalTime: ordersByABa.totalTime,
  };
};

const createDetailsItemOrder = (
  order: OrderOptimizerDetailsOrders
): OrderOptimizerDetailsItemOrders => ({
  billingModel: order.billingModel,
  cargoCompany: order.cargoCompany,
  distance: order.distance,
  driverName: order.driverName,
  fleetPartner: order.fleetPartner,
  haltingTime: order.haltingTime,
  humanId: order.humanId,
  planEntryHumanId: order.planEntryHumanId,
  startTime: order.startTime,
  taxiCorporation: order.taxiCorporation,
  totalTime: order.totalTime,
});

const createDetailsItemOrders = (
  orders: OrderOptimizerDetailsItemOrders[]
): OrderOptimizerDetailsOrders[] => {
  return orders.map(createDetailsItemOrder);
};

const createDetailsItemOptimizedStats = (
  optimizedStats: OrderOptimizerDetailsOptimizedStats
): OrderOptimizerDetailsItemOptimizedStats => {
  return {
    contractRate: optimizedStats.contractRate,
    distance: optimizedStats.distance,
    haltingTime: optimizedStats.haltingTime,
    margin: optimizedStats.margin,
    totalTime: optimizedStats.totalTime,
  };
};

const createDetailsItemUnoptimizedStats = (
  unoptimizedStats: OrderOptimizerDetailsUnoptimizedStats
): OrderOptimizerDetailsItemUnoptimizedStats => {
  return {
    contractRate: unoptimizedStats.contractRate,
    distance: unoptimizedStats.distance,
    haltingTime: unoptimizedStats.haltingTime,
    margin: unoptimizedStats.margin,
    totalTime: unoptimizedStats.totalTime,
  };
};

const createDetailsItemDriver = (
  driver: OrderOptimizerDetailsDriver | null
): OrderOptimizerDetailsItemDriver | null => {
  if (!driver) return null;
  return {
    contractRate: driver.contractRate,
    displayName: driver.displayName,
    driverId: driver.driverId,
    startLat: driver.startLat,
    startLon: driver.startLon,
    taxiCorporation: driver.taxiCorporation,
  };
};

const createDetailsItem = (
  detailsItem: OrderOptimizerDetails
): OrderOptimizerDetailsItem => {
  return {
    driver: createDetailsItemDriver(detailsItem.driver),
    optimizedStats: createDetailsItemOptimizedStats(detailsItem.optimizedStats),
    orders: createDetailsItemOrders(detailsItem.orders),
    ordersByDriverAba: createDetailsItemOrdersByAba(
      detailsItem.ordersByDriverAba
    ),
    ordersByDriverSabs: createDetailsItemOrdersBySabs(
      detailsItem.ordersByDriverSabs
    ),
    reviewedAt: detailsItem.reviewedAt,
    reviewedBy: detailsItem.reviewedBy,
    saAndBsDistance: detailsItem.saAndBsDistance,
    steps: createDetailsItemSteps(detailsItem.steps),
    unoptimizedStats: createDetailsItemUnoptimizedStats(
      detailsItem.unoptimizedStats
    ),
    score: detailsItem.score,
    totalCargoIncome: detailsItem.totalCargoIncome,
    totalDriverHaltingCost: detailsItem.totalDriverHaltingCost,
  };
};

const createDetailsItems = (
  detailsItems: OrderOptimizerDetails[]
): OrderOptimizerDetailsItem[] => {
  return detailsItems.map(createDetailsItem);
};

const orderOptimizerDetailsItemFactory = {
  createDetailsItems,
  createDetailsItem,
};

export default orderOptimizerDetailsItemFactory;
