import { useEffect, useMemo, useState } from "react";
import OrderOptimizerDetailsBreadcrumbsParams from "../common/breadcrumbs/types/order-optimizer-details-breadcrumbs-params";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../context/app.context";
import orderOptimizerBreadcrumbsHelper from "../common/breadcrumbs/order-optimizer-breadcrumbs.helper";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import MapRoute from "../../../common/components/map/types/map-route";
import useOrderOptimizerRoadRoute from "../../../common/services/order-optimizer/road-route/use-mileage-road-route";
import MapMarker from "../../../common/components/map/types/map-marker";
import orderOptimizerDetailsMapMarkersFactory from "./common/order-optimizer-details-map-markers.factory";
import orderOptimizerDetailsWaypointFactory from "./common/order-optimizer-details-waypoint.factory";
import HeadingComponent from "../../../common/components/heading/heading.component";
import CardComponent from "../../../common/components/card/card.component";
import MapComponent from "../../../common/components/map/map.component";
import ButtonComponent from "../../../common/components/button/button.component";
import orderOptimizerDetailsHelper from "./order-optimizer-details.helper";
import OrderOptimizerRoadRouteLoadParams from "../../../common/services/order-optimizer/road-route/order-optimizer-road-route-load-params";
import orderOptimizerDetailsRouteFactory from "./common/order-optimizer-details-route.factory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import orderOptimizerTranslationsHelper from "../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsOrdersTableComponent from "./orders-table/order-optimizer-details-orders-table.component";
import OrderOptimizerDetailsWithoutDriverTableComponent from "./without-driver-table/order-optimizer-details-without-driver-table.component";
import OrderOptimizerDetailsHiddenDetailsComponent from "./hidden-details/order-optimizer-details-hidden-details.component";
import OrderOptimizerDetailsDriverTableComponent from "./driver-table/order-optimizer-details-driver-table.component";
import HorizontalSlider from "../../../common/components/horizontal-slider/horizontal-slider.component";
import NumericInputComponent from "../../../common/components/form/input/numeric-input/numeric-input.component";
import FormErrorComponent from "../../../common/components/form/error/form-error.component";
import FormValidationResult from "../../../common/utils/validation/types/form-validation-result";
import orderOptimizerDetailsValidationService from "./common/order-optimizer-details-validation.service";
import orderOptimizerHelper from "../common/order-optimizer.helper";
import OrderOptimizerDetailsDriverTableRates from "./driver-table/types/order-optimizer-details-driver-table-rates";
import useOrderOptimizerDetails from "../../../common/services/order-optimizer/details/use-order-optimizer-details";
import useAbort from "../../../common/hooks/use-abort";
import OrderOptimizerDetailsLoadParams from "../../../common/services/order-optimizer/details/order-optimizer-details-load-params";
import orderOptimizerDetailsItemFactory from "./common/order-optimizer-details-item.factory";
import { round } from "lodash";
import OrderOptimizerDetailsHiddenDetailsDriver from "./hidden-details/driver/types/order-optimizer-details-hidden-details-driver";
import notificationService from "../../../common/utils/notification/notification.service";

const OrderOptimizerDetailsComponent = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();
  const { id } = useParams<OrderOptimizerDetailsBreadcrumbsParams>();
  const roadRoute = useOrderOptimizerRoadRoute();
  const translation = orderOptimizerTranslationsHelper.getDetailsTranslations();

  const orderOptimizerDetails = useOrderOptimizerDetails();
  const orderOptimizerDetailsAbort = useAbort();

  const [waypoints, setWaypoints] = useState<
    { humanId: number; lat: number; lon: number }[]
  >([]);

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const [validationResult, setValidationResult] =
    useState<FormValidationResult | null>(null);

  const loadOrderOptimizerDetails = (
    optimizationId: string,
    taxiDriverAssociationId?: string
  ) => {
    const params: OrderOptimizerDetailsLoadParams = {
      optimizationId: optimizationId,
      taxiDriverAssociationId: taxiDriverAssociationId,
    };

    orderOptimizerDetails.load(params, orderOptimizerDetailsAbort.signal);
  };

  useEffect(() => {
    if (!id) return;

    loadOrderOptimizerDetails(id);

    return orderOptimizerDetailsAbort.revoke;
  }, []);

  const detailsItem = useMemo(() => {
    if (!orderOptimizerDetails.data) return undefined;

    return orderOptimizerDetailsItemFactory.createDetailsItem(
      orderOptimizerDetails.data
    );
  }, [orderOptimizerDetails.data]);

  const [rates, setRates] = useState<OrderOptimizerDetailsDriverTableRates>({
    aba: 0,
    saAndBs: 0,
    sabs: 0,
  });

  const [validationRateResults, setValidationRateResults] = useState<
    Record<
      keyof OrderOptimizerDetailsDriverTableRates,
      FormValidationResult | null
    >
  >({
    aba: null,
    sabs: null,
    saAndBs: null,
  });

  useEffect(() => {
    const breadcrumbs = orderOptimizerBreadcrumbsHelper.getDetailsBreadcrumbs({
      id: id!,
    });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.orderOptimizerDetails.replace("#{id}", id!)
  );

  const mapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers =
      orderOptimizerDetailsMapMarkersFactory.createMapMarkers(waypoints);
    return mapMarkers;
  }, [waypoints]);

  useEffect(() => {
    if (!detailsItem) {
      return;
    }

    const preparedWaypoints =
      orderOptimizerDetailsWaypointFactory.createWaypoints(detailsItem);
    setWaypoints(preparedWaypoints.flat());

    const loadParams: OrderOptimizerRoadRouteLoadParams[] = [];

    preparedWaypoints.forEach((preparedWaypointsRoute) => {
      if (preparedWaypointsRoute.length > 1) {
        const request =
          orderOptimizerDetailsWaypointFactory.createSearchRoutingRequest(
            preparedWaypointsRoute
          );
        loadParams.push(request);
      }
    });

    roadRoute.load(loadParams);
  }, [detailsItem]);

  useEffect(() => {
    if (!roadRoute.data) return;

    const routes = roadRoute.data.map((route, index) =>
      orderOptimizerDetailsRouteFactory.createMapRoute(
        route.coordinates,
        orderOptimizerHelper.getOrderColor(index)
      )
    );
    setMapRoutes(routes);
  }, [roadRoute.data]);

  const sabsAbaDifferenceDistance = round(
    (detailsItem?.ordersByDriverSabs?.distance ?? 0) -
      (detailsItem?.ordersByDriverAba?.distance ?? 0)
  );

  const onDistanceChanged = (saAndBsDistance: number) => {
    if (!detailsItem) return;

    setSliderValue(saAndBsDistance);

    const result = orderOptimizerDetailsValidationService.validateDistance(
      saAndBsDistance,
      sabsAbaDifferenceDistance
    );
    setValidationResult(result);
  };

  const [sliderValue, setSliderValue] = useState(0);

  const handleHideDetails = () => {
    setDetailsExpanded(false);
  };

  const handleShowDetails = () => {
    setDetailsExpanded(true);
  };

  useEffect(() => {
    if (!detailsItem?.driver) return;

    const initialRates: OrderOptimizerDetailsDriverTableRates = {
      aba: detailsItem?.driver.contractRate,
      saAndBs: detailsItem?.driver.contractRate,
      sabs: detailsItem?.driver.contractRate,
    };

    setRates(initialRates);
  }, [detailsItem?.driver?.contractRate]);

  const onRateAbaChanged = (key: string, rateAba: number) => {
    setRates((prevRates) => ({
      ...prevRates,
      [key]: rateAba,
    }));

    const result = orderOptimizerDetailsValidationService.validateRate(rateAba);

    setValidationRateResults((prevResults) => ({
      ...prevResults,
      [key]: result,
    }));
  };

  const onRateSabsChanged = (key: string, rateSabs: number) => {
    setRates((prevRates) => ({
      ...prevRates,
      [key]: rateSabs,
    }));

    const result =
      orderOptimizerDetailsValidationService.validateRate(rateSabs);

    setValidationRateResults((prevResults) => ({
      ...prevResults,
      [key]: result,
    }));
  };

  const onRateSaAndBsChanged = (key: string, rateSaAndBs: number) => {
    setRates((prevRates) => ({
      ...prevRates,
      [key]: rateSaAndBs,
    }));

    const result =
      orderOptimizerDetailsValidationService.validateRate(rateSaAndBs);

    setValidationRateResults((prevResults) => ({
      ...prevResults,
      [key]: result,
    }));
  };

  const onPotentialDriverChange = (
    driver: OrderOptimizerDetailsHiddenDetailsDriver | null
  ) => {
    if (!id) return;

    loadOrderOptimizerDetails(id, driver?.taxiDriverAssociationUuid);
  };

  useEffect(() => {
    if (orderOptimizerDetails.isError) {
      notificationService.error(translation.noDriverContractErrorNotification);
    }
  }, [orderOptimizerDetails.isError]);

  const marginAba = orderOptimizerDetailsHelper.calculateMargin(
    detailsItem?.totalCargoIncome ?? 0,
    detailsItem?.totalDriverHaltingCost ?? 0,
    detailsItem?.ordersByDriverAba?.distance ?? 0,
    rates.aba
  );

  const marginSabs = orderOptimizerDetailsHelper.calculateMargin(
    detailsItem?.totalCargoIncome ?? 0,
    detailsItem?.totalDriverHaltingCost ?? 0,
    detailsItem?.ordersByDriverSabs?.distance ?? 0,
    rates.sabs
  );

  const saAndBsDistanceMargin = orderOptimizerDetailsHelper.calculateMargin(
    detailsItem?.totalCargoIncome ?? 0,
    detailsItem?.totalDriverHaltingCost ?? 0,
    (detailsItem?.ordersByDriverAba?.distance ?? 0) + sliderValue,
    rates.saAndBs
  );

  const saAndBsMarginLabel = orderOptimizerDetailsHelper.getMarginLabel(
    saAndBsDistanceMargin
  );
  const saAndBsMarginTitle = orderOptimizerDetailsHelper.getMarginLabel(
    saAndBsDistanceMargin
  );

  const scoreLabel = orderOptimizerDetailsHelper.getScoreLabel(
    detailsItem?.score
  );

  const idLabel = orderOptimizerDetailsHelper.getIdLabel(Number(id));

  return (
    <>
      <HeadingComponent
        title={orderOptimizerDetailsHelper.getHeadingText(id!)}
      />
      <CardComponent>
        <div className="order_optimizer_details">
          <div className="order_optimizer_details__left">
            <div className="order_optimizer_details_map_wrapper">
              <MapComponent
                markers={mapMarkers}
                routes={mapRoutes}
                autoFitOnUpdate
              />
            </div>
            {detailsExpanded ? (
              <ButtonComponent
                onClick={handleHideDetails}
                classNames={{ root: "order_optimizer_details__left__button" }}
                title={translations.hideDetailsButtonTitle}
                type="primary"
              >
                {translations.hideDetailsButtonLabel}
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="order_optimizer_details__left__button__icon"
                />
              </ButtonComponent>
            ) : (
              <ButtonComponent
                onClick={handleShowDetails}
                classNames={{ root: "order_optimizer_details__left__button" }}
                title={translations.showDetailsButtonTitle}
                type="primary"
                isDisabled={!detailsItem}
              >
                {translations.showDetailsButtonLabel}
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="order_optimizer_details__left__button__icon"
                />
              </ButtonComponent>
            )}
          </div>
          <div className="order_optimizer_details__right">
            <div className="order_optimizer_details__right__info">
              <div className="order_optimizer_details__right__info__left">
                <h1 className="order_optimizer_details__right__info__left__text">
                  {scoreLabel}
                </h1>
                <h1 className="order_optimizer_details__right__info__left__text">
                  {idLabel}
                </h1>
              </div>
              <div className="order_optimizer_details__right__info__right">
                {!!detailsItem?.reviewedAt && !!detailsItem.reviewedBy && (
                  <div
                    className="order_optimizer_details__right__info__right__badge"
                    title={`${
                      detailsItem?.reviewedBy
                    }, ${orderOptimizerDetailsHelper.getDateLabel(
                      detailsItem?.reviewedAt ?? null
                    )}`}
                  >
                    {detailsItem?.reviewedBy},{" "}
                    {orderOptimizerDetailsHelper.getDateLabel(
                      detailsItem?.reviewedAt ?? null
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="order_optimizer_details_orders_table">
              <OrderOptimizerDetailsOrdersTableComponent
                detailsOrderItems={detailsItem?.orders}
              />
            </div>
            <div className="order_optimizer_details_without_driver_table">
              <OrderOptimizerDetailsWithoutDriverTableComponent
                detailsOptimizedStatsItem={detailsItem?.optimizedStats}
                detailsUnoptimizedStatsItem={detailsItem?.unoptimizedStats}
              />
            </div>
            <div className="order_optimizer_details_driver_table">
              <OrderOptimizerDetailsDriverTableComponent
                detailsItem={detailsItem}
                marginAba={marginAba}
                marginSabs={marginSabs}
                validationResult={validationRateResults}
                onRateAbaChanged={onRateAbaChanged}
                onRateSaAndBsChanged={onRateSaAndBsChanged}
                onRateSabsChanged={onRateSabsChanged}
                rates={rates}
              />
            </div>
            <div className="order_optimizer_details_driver_horizontal_slider_table">
              <div
                title={translations.table.driver.grantedSaAndBsDistanceTitle}
                className="order_optimizer_details_driver_horizontal_slider_table__cell"
              >
                <div className="order_optimizer_details_driver_horizontal_slider_table__cell__text">
                  {translations.table.driver.grantedSaAndBsDistanceLabel}
                </div>
              </div>
              <div className="order_optimizer_details_driver_horizontal_slider_table__slider">
                <HorizontalSlider
                  value={sliderValue}
                  onChange={onDistanceChanged}
                  maxValue={sabsAbaDifferenceDistance}
                />
              </div>
              <div className="order_optimizer_details_driver_horizontal_slider_table__cell_input">
                <div
                  title={`${sliderValue} KM`}
                  className="order_optimizer_details_driver_horizontal_slider_table__cell_input__label"
                >
                  <NumericInputComponent
                    value={sliderValue ?? 0}
                    onChange={(value) => onDistanceChanged(value ?? 0)}
                    hasError={!!validationResult?.errorMessage}
                    placeholder={
                      translations.table.driver.distancePlaceholderLabel
                    }
                    isIntegerOnly
                  />
                  <FormErrorComponent
                    message={validationResult?.errorMessage}
                  />
                </div>
              </div>
              <div className="order_optimizer_details_driver_horizontal_slider_table__cell_margin">
                <div
                  title={saAndBsMarginTitle}
                  className="order_optimizer_details_driver_horizontal_slider_table__cell__text"
                >
                  {saAndBsMarginLabel}
                </div>
              </div>
            </div>
          </div>
        </div>
        {detailsExpanded && !!detailsItem && (
          <OrderOptimizerDetailsHiddenDetailsComponent
            optimizationId={id}
            detailsItem={detailsItem}
            onPotentialDriverChange={onPotentialDriverChange}
          />
        )}
      </CardComponent>
    </>
  );
};

export default OrderOptimizerDetailsComponent;
