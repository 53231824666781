import OrderOptimizerListResponse, {
  OrderOptimizerListResponseItem,
  OrderOptimizerListResponseItemRoute,
} from "../../../api/raily/order-optimizer/list/order-optimizer-list.response";
import OrderOptimizerList, {
  OrderOptimizerListItem,
  OrderOptimizerListItemRoute,
} from "./order-optimizer-list";

const createOrderRoute = (
  responseItemOrderRoute: OrderOptimizerListResponseItemRoute
): OrderOptimizerListItemRoute => {
  const route: OrderOptimizerListItemRoute = {
    orderHumanId: responseItemOrderRoute.orderHumanId,
    lat: responseItemOrderRoute.lat,
    lon: responseItemOrderRoute.lon,
  };

  return route;
};

const createOrderRoutes = (
  responseItemOrderRoute: OrderOptimizerListResponseItemRoute[]
): OrderOptimizerListItemRoute[] => {
  return responseItemOrderRoute.map(createOrderRoute);
};

const createItem = (
  responseItem: OrderOptimizerListResponseItem
): OrderOptimizerListItem => {
  const item: OrderOptimizerListItem = {
    id: responseItem.id,
    ordersHumanIds: responseItem.ordersHumanIds,
    reviewedAt: responseItem.reviewedAt,
    reviewedBy: responseItem.reviewedBy,
    route: createOrderRoutes(responseItem.route),
    score: responseItem.score,
  };

  return item;
};

const createData = (
  responseData: OrderOptimizerListResponseItem[]
): OrderOptimizerListItem[] => {
  return responseData.map(createItem);
};

const create = (response: OrderOptimizerListResponse): OrderOptimizerList => {
  const orderOptimizerList: OrderOptimizerList = {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };

  return orderOptimizerList;
};

const orderOptimizerListFactory = {
  create,
};

export default orderOptimizerListFactory;
