import OrderOptimizerDetailsRequest, {
  OrderOptimizerDetailsQueryParams,
} from "./order-optimizer-details.request";

const createQueryParams = (
  request: OrderOptimizerDetailsRequest
): OrderOptimizerDetailsQueryParams => {
  return {
    taxiDriverAssociationId: request.taxiDriverAssociationId,
  };
};

const orderOptimizerDetailsRequestFactory = {
  createQueryParams,
};

export default orderOptimizerDetailsRequestFactory;
